<template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0"/>
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>
                <a-breadcrumb-item>评论列表</a-breadcrumb-item>
            </a-breadcrumb>
            <div class="toolsbar">
                <div style="float: left;margin-left: 20px;margin-top: 10px;">
<!--                    <a-button type="primary" @click="addadmin()">新建评论</a-button>-->
                    <a-button type="primary" :loading="loading" @click="start" style="margin-left: 20px">
                        刷新
                    </a-button>
                </div>
                <div class="serach_input">
<!--                    <a-input-group compact>-->
<!--                        <a-select defaultValue="用户名">-->
<!--                            <a-select-option value="username">用户名</a-select-option>-->
<!--                            &lt;!&ndash;                    <a-select-option value="username">用户名</a-select-option>&ndash;&gt;-->
<!--                        </a-select>-->
<!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
<!--                    </a-input-group>-->
                </div>
            </div>

            <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
<!--                                {{data}}-->

                <a-table
                        bordered
                        :columns="columns"
                        :dataSource="data"
                        :pagination="pagination"
                        :loading="loading"
                        rowKey="id"
                >

                    <template slot="status" slot-scope="text, index" style="text-align: center">
                        <a-tag :color="'green'" v-if="index.status === 1"><span>正常</span></a-tag>
                        <a-tag :color="'red'" v-if="index.status !== 1">封停</a-tag>
                    </template>
                    <template slot="operation" slot-scope="text, index">
<!--                            <span style="margin-right: 5px;">-->
<!--                              <a @click="showModal(index)">编辑</a>-->
<!--                            </span>-->
                            <span>
                              <a @click="deleadmin(index)">剔除</a>
                            </span>
                    </template>

                </a-table>
            </div>
        </a-layout-content>
        <a-layout-footer style="text-align: center"></a-layout-footer>
    </a-layout>

</template>

<script>
    const columns = [
        {
            title: "id",
            dataIndex: "id",
            width: "5%",
            scopedSlots: {customRender: "id"}
        },
        {
            title: "名字",
            dataIndex: "contact_name",
            width: "8%",
            scopedSlots: {customRender: "contact_name"}
        },
        {
            title: "联系方式",
            dataIndex: "contact_info",
            width: "10%",
            scopedSlots: {customRender: "contact_info"}
        },

        {
            title: "评论内容",
            dataIndex: "content",
            width: "30%",
            scopedSlots: {customRender: "content"}
        },
        {
            title: "发送时间",
            dataIndex: "create_time",
            width: "10%",
            scopedSlots: {customRender: "create_time"}
        },
        {
            title: "状态",
            width: "5%",
            dataIndex: "status",
            scopedSlots: {customRender: "status"}
        },
        {
            title: '操作',
            width: "7%",
            key: 'operation',
            scopedSlots: {customRender: 'operation'}
        },
    ];

    import {Modal} from "ant-design-vue";
    import Storage from "../common/storage";
    import moment from "moment";
    // import * as Util from "../common/util";

    export default {
        name: "Dashboard",
        components: {},
        data() {
            return {
                columns,
                data: [],
                pagination: {
                    pageSize: 10
                },
                popinfos: false,
                popadd: false,
                search_text: "",
                loading: false,
                add: {
                    login_name: "",
                    password: "",
                    re_password: "",
                    name: "",
                    sex: "",
                    mobile: "",
                    type: "",
                    group_id: "",
                },
                edit: {
                    uid: "",
                    login_name: "",
                    password: "",
                    re_password: "",
                    name: "",
                    sex: "2",
                    mobile: "",
                },
            };
        },
        created() {
            // 获取第一页内容
            this.doSearch(1, this.pagination.pageSize, "");
        },
        methods: {
            start() {
                this.loading = true;
                this.data = [];
                // ajax request after empty completing
                setTimeout(() => {
                    this.loading = false;
                    this.selectedRowKeys = [];
                }, 1000);
                this.doSearch(1, this.pagination.pageSize);
            },

            async onSearch(text) {
                this.data = []
                this.search_text = text;
                this.doSearch(1, this.pagination.pageSize, text);
            },

            async onChange(pagination) {
                await this.doSearch(
                    this.search_text,
                    pagination.current - 1,
                    pagination.pageSize
                );
            },

            async onChanges(e) {
                console.log(e.target.value);
            },

            showModal(item) {
                console.log(item)
                this.edit.login_name = item.login_name;
                this.edit.password = item.password;
                this.edit.re_password = item.re_password;
                this.edit.name = item.name;
                this.edit.sex = item.sex.toString();
                this.edit.mobile = item.mobile;
                this.edit.uid = item.uid;
                this.edit.type = 2;
                this.popinfos = true;
            },
            addadmin() {
                this.add.login_name = "";
                this.add.password = "";
                this.add.re_password = "";
                this.add.name = "";
                this.add.sex = "1";
                this.add.type = 2;
                this.add.mobile = "";
                // this.add.group_id = "";
                this.popadd = true;
            },
            async popok() {
                if (this.edit.login_name =="") {
                    Modal.warning({
                        title: "请输入用户名"
                    });
                    this.popinfos = true;
                    return
                }
                if (this.edit.password != this.edit.re_password) {
                    Modal.warning({
                        title: "两次密码不一致"
                    });
                    this.popinfos = true;
                    return
                }
                let now = Date.now();
                let result = await this.$post("/api/admin/account/update", {
                    uid: Storage.uid,
                    token: Storage.token,
                    edit_uid: this.edit.uid,
                    save_value:{
                        login_name: this.edit.login_name,
                        password: this.edit.password,
                        name: this.edit.name,
                        sex: this.edit.sex,
                        mobile: this.edit.mobile,
                        t: now,
                    },

                });

                if (result.status === true) {
                    this.data = []
                    await this.doSearch(1, this.pagination.pageSize, "");
                    Modal.info({
                        title: "更新成功"
                    });
                }
                this.popinfos = false;
            },
            async addok() {


                if (this.add.login_name =="") {
                    Modal.warning({
                        title: "请输入用户名"
                    });
                    this.popinfos = true;
                    return
                }
                if (this.add.password =="") {
                    Modal.warning({
                        title: "请输入登录密码"
                    });
                    this.popinfos = true;
                    return
                }
                if (this.add.password != this.add.re_password) {
                    Modal.warning({
                        title: "两次密码不一致"
                    });
                    this.popinfos = true;
                    return
                }

                let now = Date.now();
                let result = await this.$post("/api/admin/account/create", {
                    uid: Storage.uid,
                    token: Storage.token,
                    save_value: {
                        login_name: this.add.login_name,
                        password: this.add.password,
                        name: this.add.name,
                        sex: this.add.sex,
                        mobile: this.add.mobile,
                        type: this.add.type,
                        group_id: this.add.group_id,
                        t: now,
                    },
                });

                if (result.status === true) {
                    this.data = []
                    await this.doSearch(1, this.pagination.pageSize, "");
                    Modal.info({
                        title: "创建成功"
                    });
                }
                this.popadd = false;
            },
            async doSearch(page, limit, article_id) {
                this.loading = true;
                this.data = [];
                console.log(Storage.uid)
                console.log(Storage.token)
                let now = Date.now();
                let result = await this.$get("/api/admin/articleComments/list", {
                    params: {
                        token: Storage.token,
                        uid: Storage.uid,
                        page: page,
                        limit: limit,
                        article_id: article_id,
                        user_type: 2,
                        t: now,
                    }
                });

                if (result.status === true) {
                    let data = [...this.data];
                    for (let i in result.data.list) {
                        let info = result.data.list[i];
                        let offset = (parseInt(page) - 1) * limit + parseInt(i);
                        // console.log(offset)

                        data[offset] = {
                            id: info.id,
                            article_id: info.article_id,
                            contact_name: info.contact_name,
                            contact_info: info.contact_info,
                            content: info.content,

                            create_time: moment(info.create_time * 1000).format("YYYY/MM/DD HH:mm:ss"),
                            // login_time: info.login_time,
                            status: info.status,
                        };
                    }

                    let pagination = {...this.pagination};
                    pagination.total = result.data.total;
                    this.pagination = pagination;
                    this.data = data;
                    this.loading = false;
                }

                this.loading = false;
            },
            async deleadmin(item) {
                var that = this;
                console.log(item);
                Modal.confirm({
                    title: "警告！",
                    content: "是否确认剔除该评论?" ,

                    onOk: async function () {

                        let result = await that.$post("/api/admin/articleComments/delete", {

                            uid: Storage.uid,
                            token: Storage.token,
                            id: item.id,
                            // notes: this.edit_pop.notes,
                            t: Date.now(),
                            // sign: Crypt.sign([
                            //     Storage.token,
                            //     this.edit_pop.id,
                            //     JSON.stringify(data),
                            //     this.edit_pop.notes,
                            //     this.$config.secret_key,
                            //     now
                            // ])
                        });
                        console.log(result);
                        if (result.status == true) {
                            console.log("准备弹窗！");
                            that.data = []
                            await that.doSearch(1, that.pagination.pageSize, "");

                            Modal.info({
                                title: "剔除成功"
                            });
                        }
                        if(result.status === false){
                            // that.data = []
                            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
                            Modal.info({
                                title: result.msg
                            });
                        }
                    }

                });


            },
        }
    };
</script>
<style scoped>
    .editable-row-operations a {
        margin-right: 8px;
    }

    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    #components-layout-demo-custom-trigger .logo {
        height: 32px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px;
    }

    .serach_input {
        width: 25vw;
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 25px;
    }

    .pages {
        float: right;
        margin-top: 10px;
    }

    .toolsbar {
        display: block;
        width: 100%;
        height: 42px;
        background: #fff
    }
</style>
